import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { MUIPremiumKey } from '@top-solution/microtecnica-mui';
import { CommonDataApiProvider, setAuthApiUrl } from '@top-solution/microtecnica-utils';
import { createRoot } from 'react-dom/client';
import { grey } from '@mui/material/colors';
import secondary from '@mui/material/colors/blueGrey';
import primary from '@mui/material/colors/indigo';
import CssBaseline from '@mui/material/CssBaseline';
import { itIT as coreItIT } from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { itIT as dataGridLocale } from '@mui/x-data-grid-premium/locales';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400-italic.css';
import App from './App';
import { store } from './store';

const appId = `${import.meta.env.VITE_APP_ID}`;
const appVersion = `${import.meta.env.VITE_VERSION}`;
const authApi = `${import.meta.env.VITE_AUTH_API}`;

LicenseInfo.setLicenseKey(MUIPremiumKey);

setAuthApiUrl(`${import.meta.env.VITE_AUTH_API}/api`);

// eslint-disable-next-line
console.log(`🗄 ${appId} (v. ${appVersion}) 🗄`);

const theme = createTheme(
  {
    palette: {
      primary,
      secondary,
      background: {
        default: grey[50],
      },
    },
  },
  dataGridLocale,
  coreItIT
);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <CommonDataApiProvider value={{ appId, authApi }}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <CssBaseline />
            <App />
          </HelmetProvider>
        </ThemeProvider>
      </CommonDataApiProvider>
    </Provider>
  </StrictMode>
);
