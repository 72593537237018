import { Breadcrumb, DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { ADMIN, AuthGuard, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { Layout } from '../components/Layout';
import UnauthorizedPage from './UnauthorizedPage';

export const breadcrumbs: Breadcrumb[] = [{ title: 'Elenco siti', url: '/plants' }];

const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'name', headerName: 'Nome', minWidth: 120 },
  { field: 'slug', headerName: 'Slug' },
];

function PlantListPage(): JSX.Element {
  const { data, error, isLoading } = useReadPlantsQuery();

  return (
    <AuthGuard authorizeRole={(r) => r === ADMIN} unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} inProgress={isLoading} error={error} sx={{ py: 1 }}>
        <DataGridWrapper autoHeight>
          <DataGrid rows={data?.list || []} columns={columns} disableColumnMenu autoHeight autoPageSize />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}

export default PlantListPage;
