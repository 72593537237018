import { useEffect } from 'react';
import { Route, BrowserRouter, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useAuth, AuthCallback } from '@top-solution/microtecnica-utils';
import {
  AddressBook,
  AppListPage,
  AppUserListPage,
  CountryListPage,
  HomePage,
  NotFoundPage,
  PlantListPage,
  UnauthorizedPage,
} from './pages';
import ProgramListPage from './pages/Program/ProgramListPage';

const TOKEN_REFRESH_TIMEOUT = 60 * 30 * 1e3;

function CommonDataAuthCallback() {
  const navigate = useNavigate();
  return <AuthCallback onAuthenticationSuccess={(path) => navigate(path)} />;
}

function App(): JSX.Element {
  const { token, refreshToken } = useAuth();

  useEffect(() => {
    if (token) {
      setTimeout(refreshToken, TOKEN_REFRESH_TIMEOUT);
    }
  }, [refreshToken, token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="address-book" element={<AddressBook />} />
        <Route path="plants" element={<PlantListPage />} />
        <Route path="countries" element={<CountryListPage />} />
        <Route path="apps/*">
          <Route index element={<AppListPage />} />
          <Route path=":appId" element={<Navigate to="./users" />} />
          <Route path=":appId/users" element={<AppUserListPage />} />
        </Route>
        <Route />
        <Route path="programs" element={<ProgramListPage />} />
        <Route path="/auth" element={<CommonDataAuthCallback />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
