import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AppUserListGrid } from '@top-solution/microtecnica-mui';
import { ADMIN, AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { useReadAppListQuery } from '../../services/appApi';
import { breadcrumbs as appListPageBreadcrumbs } from '../AppListPage';
import UnauthorizedPage from '../UnauthorizedPage';

export default function AppUserListPage(): JSX.Element {
  const { appId } = useParams<'appId'>();
  const { data: appList } = useReadAppListQuery();

  const breadcrumbs = useMemo(
    () => [
      ...appListPageBreadcrumbs,
      { title: appList?.find(({ id }) => id === appId)?.name || appId, url: `/apps/${appId}` },
    ],
    [appId, appList]
  );

  return (
    <AuthGuard authorizeRole={(r) => r === ADMIN} unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        {appId && <AppUserListGrid appId={appId} />}
      </Layout>
    </AuthGuard>
  );
}
