import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PlantSelect, ErrorAlert } from '@top-solution/microtecnica-mui';
import { Person, PersonForm, PersonFormSchema, useCreatePersonMutation } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Form, FormRow } from '../../components/Form';
import { UserAddIcon } from '../../components/Icons';

const defaultValues: PersonForm = {
  lastname: '',
  firstname: '',
  company: '',
  email: '',
  mobileNumber: '',
  mobileNumberShort: '',
  phoneNumber: '',
  phoneNumberShort: '',
  plant: null,
  displayname: '',
};

export type FormRef = {
  triggerSubmit: () => Promise<Person>;
};

type PersonAddButtonProps = Omit<IconButtonProps, 'onClick'> & {
  onPersonAdd?: (person: Person) => void;
};

export default function PersonAddButton(props: PersonAddButtonProps): JSX.Element {
  const { onPersonAdd, ...buttonProps } = props;
  const { handleSubmit, control, reset, formState } = useForm<PersonForm>({
    defaultValues,
    resolver: zodResolver(PersonFormSchema),
  });
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [create, { isLoading, error }] = useCreatePersonMutation();

  const handleClose = useCallback(async () => {
    setShowAddDialog(false);
    setTimeout(() => {
      reset();
    }, 100);
  }, [reset]);

  const onSubmit = useCallback(
    async (values: PersonForm) => {
      const parsed = Object.entries(values).reduce((person, [key, value]) => {
        if (key === 'plant' && value && typeof value === 'object') {
          person.plantID = value.id;
        } else if (key !== 'id' && value !== '') {
          person[key] = value;
        }
        return person;
      }, {} as Record<string, unknown>);
      const person = parsed as Person;
      await create(person as Person).unwrap();
      if (onPersonAdd) {
        onPersonAdd(person);
      }
      handleClose();
    },
    [create, handleClose, onPersonAdd]
  );

  return (
    <>
      <Tooltip title="Nuovo contatto">
        <IconButton size="small" color="primary" onClick={() => setShowAddDialog(true)} {...buttonProps}>
          <UserAddIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog open={showAddDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Inserire solo contatti di persone esterne all&apos;azienda!</AlertTitle>
            Tutti gli utenti dotati di Windows Authentication vengono importati automaticamente nella rubrica aziendale.
          </Alert>
          <Form sx={{ mt: 2 }}>
            <FormRow>
              <Controller
                control={control}
                name="lastname"
                render={({ field, formState: { errors } }) => (
                  <TextField label="Cognome" error={Boolean(errors.displayname)} sx={{ flex: 1 }} {...field} />
                )}
              />
              <Controller
                control={control}
                name="firstname"
                render={({ field, formState: { errors } }) => (
                  <TextField label="Nome" error={Boolean(errors.displayname)} sx={{ flex: 1 }} {...field} />
                )}
              />
            </FormRow>
            {formState.errors.displayname && (
              <FormHelperText error sx={{ mx: 1, my: 1 }}>
                Compilare almeno uno dei campi Cognome, Nome o Società
              </FormHelperText>
            )}
            <FormRow>
              <Controller
                control={control}
                name="company"
                render={({ field, formState: { errors } }) => (
                  <TextField label="Società" error={Boolean(errors.displayname)} sx={{ flex: 2 }} {...field} />
                )}
              />
              <Controller
                control={control}
                name="plant"
                render={({ field, fieldState: { invalid } }) => (
                  <PlantSelect
                    label="Sito"
                    error={invalid}
                    sx={{ flex: 1 }}
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                  />
                )}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField label="Email" error={invalid} helperText={error?.message} sx={{ flex: 1 }} {...field} />
                )}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField label="Telefono" error={invalid} helperText={error?.message} sx={{ flex: 3 }} {...field} />
                )}
              />
              <Controller
                control={control}
                name="phoneNumberShort"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    label="Telefono abbreviato"
                    error={invalid}
                    helperText={error?.message}
                    sx={{ flex: 2 }}
                    {...field}
                  />
                )}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name="mobileNumber"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField label="Mobile" error={invalid} helperText={error?.message} sx={{ flex: 3 }} {...field} />
                )}
              />
              <Controller
                control={control}
                name="mobileNumberShort"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    label="Mobile abbreviato"
                    error={invalid}
                    helperText={error?.message}
                    sx={{ flex: 2 }}
                    {...field}
                  />
                )}
              />
            </FormRow>
          </Form>
          {error && <ErrorAlert error={error} />}
        </DialogContent>
        <DialogActions sx={{ px: 2 }}>
          <Button color="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)} loading={isLoading}>
            Salva
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
