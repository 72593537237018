import { useMemo } from 'react';
import { Breadcrumb, DataGrid, DataGridWrapper, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { AuthGuard, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Layout } from '../components/Layout';
import UnauthorizedPage from './UnauthorizedPage';

export const breadcrumbs: Breadcrumb[] = [{ title: 'Elenco nazioni', url: '/countries' }];

function CountryListPage(): JSX.Element {
  const { data, error, isLoading } = useReadCountriesQuery();
  const countryColumn = useCountryGridColDef({ format: 'png', hideLabel: true });

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', filterable: true, width: 80 },
      {
        ...countryColumn,
        field: 'flag',
        headerName: 'Bandiera',
        filterable: false,
        sortable: false,
        disableExport: true,
        width: 80,
      },
      { field: 'isEU', type: 'boolean', headerName: 'Paese EU', filterable: true, width: 100 },
      { field: 'isNato', type: 'boolean', headerName: 'Paese Nato', filterable: true, width: 100 },
      { field: 'name', headerName: 'Nome', filterable: true, flex: 1, maxWidth: 500, minWidth: 100 },
      { field: 'geonomCode', headerName: 'Codice Geonom', filterable: true, width: 160 },
    ],
    [countryColumn]
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={isLoading}
        error={error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>{data && <DataGrid density="compact" rows={data.list} columns={columns} />}</DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}

export default CountryListPage;
