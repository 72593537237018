import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Config, ConfigSchema } from '../entities/Config';
import { RootState } from '../store';

export const TAG_TYPES = {
  PERSON: 'Person',
  PROGRAM: 'Program',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: (builder) => ({
    getToken: builder.query<string, void>({
      query: () => 'token',
    }),
    getConfig: builder.query<Config, void>({
      query: () => 'v1/config',
      transformResponse: (data) => ConfigSchema.parse(data),
    }),
    setConfig: builder.mutation<void, Config>({
      query: (config) => ({
        url: 'v1/config',
        method: 'PUT',
        body: config,
      }),
    }),
  }),
});

export const { useLazyGetTokenQuery, useGetConfigQuery, useSetConfigMutation } = api;
