import { Link } from 'react-router-dom';
import { Breadcrumb } from '@top-solution/microtecnica-mui';
import { ADMIN, AuthGuard } from '@top-solution/microtecnica-utils';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { Layout } from '../components/Layout';
import { useReadAppListQuery } from '../services/appApi';
import UnauthorizedPage from './UnauthorizedPage';

export const breadcrumbs: Breadcrumb[] = [{ title: 'Gestione utenti', url: '/apps' }];

function AppListPage(): JSX.Element {
  const { data, error, isLoading } = useReadAppListQuery();

  return (
    <AuthGuard authorizeRole={(r) => r === ADMIN} unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth="sm" breadcrumbs={breadcrumbs} inProgress={isLoading} error={error} sx={{ p: 1 }}>
        <Paper>
          <List disablePadding>
            {data?.map(({ name, id }) => (
              <ListItem disableGutters key={id}>
                <ListItemButton component={Link} to={`/apps/${id}`}>
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Layout>
    </AuthGuard>
  );
}

export default AppListPage;
