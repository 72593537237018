import { Link } from 'react-router-dom';
import { useAuth, AuthGuard } from '@top-solution/microtecnica-utils';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { PlantIcon, ProgramIcon, UserListIcon, CountryIcon, PersonListIcon } from '../components/Icons';
import { Layout } from '../components/Layout';
import UnauthorizedPage from './UnauthorizedPage';

function HomePage(): JSX.Element {
  const { isAdmin } = useAuth();
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth="sm" sx={{ '.MuiPaper-root': { my: 2 } }}>
        {isAdmin && (
          <Paper>
            <List>
              <ListItem disableGutters>
                <ListItemButton component={Link} to="/apps">
                  <ListItemIcon>
                    <UserListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gestione utenti" />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters>
                <ListItemButton component={Link} to="/programs">
                  <ListItemIcon>
                    <ProgramIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gestione programmi" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        )}
        <Paper>
          <List>
            <ListItem disableGutters>
              <ListItemButton component={Link} to="/address-book">
                <ListItemIcon>
                  <PersonListIcon />
                </ListItemIcon>
                <ListItemText primary="Rubrica aziendale" />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton component={Link} to="/plants">
                <ListItemIcon>
                  <PlantIcon />
                </ListItemIcon>
                <ListItemText primary="Elenco siti" />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton component={Link} to="/countries">
                <ListItemIcon>
                  <CountryIcon />
                </ListItemIcon>
                <ListItemText primary="Elenco nazioni" />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Layout>
    </AuthGuard>
  );
}

export default HomePage;
