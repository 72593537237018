import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb, Header, ServerErrorBox } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import Container, { ContainerProps } from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { FullPageProgress } from '../Progress';
// import Header, { Breadcrumb } from './Header';

type AppLayoutProps = ContainerProps & {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: React.ReactNode;
};

const APP_NAME = 'Common Data Manager';

export default function Layout(props: AppLayoutProps): React.ReactElement {
  const { children, title, breadcrumbs, inProgress, error, progressIndicator, maxWidth, ...containerProps } = props;
  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header logo={{ title: APP_NAME }} position="fixed" breadcrumbs={breadcrumbs} />
      <Toolbar />
      <Container maxWidth={maxWidth} {...containerProps}>
        {inProgress ? progressIndicator || <FullPageProgress /> : error ? <ServerErrorBox error={error} /> : children}
      </Container>
    </>
  );
}
