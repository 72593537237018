import React from 'react';
import { NotFoundBox } from '@top-solution/microtecnica-mui';
import { Layout } from '../components/Layout';

function NotFoundPage(): React.ReactElement {
  return (
    <Layout title="Pagina non trovata" maxWidth="sm">
      <NotFoundBox />
    </Layout>
  );
}

export default NotFoundPage;
