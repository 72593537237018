import { App, AppSchema } from '../entities/App';
import { api } from './baseApi';

const url = 'v1/apps';

const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readAppList: builder.query<App[], void>({
      query: () => url,
      keepUnusedDataFor: 3600,
      transformResponse: (data: unknown) => AppSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadAppListQuery } = appApi;
