import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import UserList from '@mdi/svg/svg/account-multiple.svg?react';
import UserAdd from '@mdi/svg/svg/account-plus.svg?react';
import User from '@mdi/svg/svg/account.svg?react';
import Program from '@mdi/svg/svg/airplane.svg?react';
import PersonList from '@mdi/svg/svg/book-account.svg?react';
import ChevronDown from '@mdi/svg/svg/chevron-down.svg?react';
import ChevronLeft from '@mdi/svg/svg/chevron-left.svg?react';
import ChevronRight from '@mdi/svg/svg/chevron-right.svg?react';
import ChevronUp from '@mdi/svg/svg/chevron-up.svg?react';
import Cancel from '@mdi/svg/svg/close.svg?react';
import Save from '@mdi/svg/svg/content-save.svg?react';
import Plant from '@mdi/svg/svg/domain.svg?react';
import Country from '@mdi/svg/svg/earth.svg?react';
import Edit from '@mdi/svg/svg/pencil.svg?react';
import Add from '@mdi/svg/svg/plus-circle.svg?react';
import Delete from '@mdi/svg/svg/trash-can.svg?react';
import Logo from './logo.svg';

export const createIcon = (icon: React.ReactNode) =>
  function Icon(props: SvgIconProps): JSX.Element {
    return <SvgIcon {...props}>{icon}</SvgIcon>;
  };

export const LogoIcon = createIcon(<Logo />);
export const AddIcon = createIcon(<Add />);
export const CancelIcon = createIcon(<Cancel />);
export const ChevronDownIcon = createIcon(<ChevronDown />);
export const ChevronLeftIcon = createIcon(<ChevronLeft />);
export const ChevronRightIcon = createIcon(<ChevronRight />);
export const ChevronUpIcon = createIcon(<ChevronUp />);
export const CountryIcon = createIcon(<Country />);
export const DeleteIcon = createIcon(<Delete />);
export const EditIcon = createIcon(<Edit />);
export const PersonListIcon = createIcon(<PersonList />);
export const PlantIcon = createIcon(<Plant />);
export const ProgramIcon = createIcon(<Program />);
export const SaveIcon = createIcon(<Save />);
export const UserAddIcon = createIcon(<UserAdd />);
export const UserIcon = createIcon(<User />);
export const UserListIcon = createIcon(<UserList />);
