import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function Form(props: BoxProps) {
  const { children, sx, ...boxProps } = props;
  return (
    <Box className="Form-root" sx={{ '.FormRow-root + .FormRow-root': { marginTop: 2 }, ...sx }} {...boxProps}>
      {children}
    </Box>
  );
}

export default memo(Form);
