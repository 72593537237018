import { Breadcrumb, ProgramListDataGrid } from '@top-solution/microtecnica-mui';
import { ADMIN, AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';

export const breadcrumbs: Breadcrumb[] = [{ title: 'Gestione programmi', url: '/programs' }];
export default function ProgramListPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === ADMIN}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <ProgramListDataGrid />
      </Layout>
    </AuthGuard>
  );
}
