import { UnauthorizedBox } from '@top-solution/microtecnica-mui';
import { Layout } from '../components/Layout';

type UnauthorizedPageProps = {
  description?: string;
};

function UnauthorizedPage(props: UnauthorizedPageProps): JSX.Element {
  const { description } = props;
  return (
    <Layout title="Accesso non autorizzato" maxWidth="sm">
      <UnauthorizedBox description={description} />
    </Layout>
  );
}

export default UnauthorizedPage;
