import React, { useCallback, useMemo, useState } from 'react';

import {
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  ErrorAlert,
  muiFiltersToPagedRequestFilters,
  usePagination,
  usePlantColDef,
} from '@top-solution/microtecnica-mui';
import {
  useAuth,
  ADMIN,
  AuthGuard,
  ApiError,
  PersonSchema,
  Person,
  getPersonDisplayName,
  useReadPersonListQuery,
  useRemovePersonMutation,
  useUpdatePersonMutation,
} from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import Dialog from '@mui/material/Dialog';
import {
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  MuiEvent,
  GridLogicOperator,
  useGridApiRef,
  GridActionsCellItem,
  GridRowParams,
  GridRowModes,
  GridActionsColDef,
  GridActionsCellItemProps,
  GridRowModel,
  GridPinnedColumnFields,
} from '@mui/x-data-grid-premium';

import { EditIcon, DeleteIcon, CancelIcon, SaveIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import UnauthorizedPage from '../UnauthorizedPage';
import PersonAddButton from './PersonAddButton';

const breadcrumbs = [{ title: 'Rubrica aziendale' }];
const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

export default function AddressBook(): JSX.Element {
  const apiRef = useGridApiRef();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [personToDelete, setPersonToDelete] = useState<Person | null>(null);
  const [updateError, setUpdateError] = useState<ZodError | ApiError | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'lastname', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const { isAdmin } = useAuth();
  const readPersonListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel]
  );
  const personList = useReadPersonListQuery(readPersonListParams);
  const plantColumn = usePlantColDef();
  const [updatePerson, updatePersonState] = useUpdatePersonMutation();
  const [removePerson, removePersonState] = useRemovePersonMutation();

  const handleConfirmRemove = useCallback(
    async (confirm: boolean) => {
      if (confirm && personToDelete) {
        await removePerson(personToDelete).unwrap();
      }
      setPersonToDelete(null);
    },
    [personToDelete, removePerson]
  );

  const handleEditClick = useCallback(
    ({ id }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        apiRef.current.startRowEditMode({ id });
      },
    [apiRef]
  );

  const handleCancelClick = useCallback(
    ({ id }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        apiRef.current.stopRowEditMode({ id });
      },

    [apiRef]
  );

  const handleSaveClick = useCallback(
    ({ id }: GridRowParams) =>
      async (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        const previousValues = (apiRef.current.getRow(id) as Record<string, unknown>) || { id };
        const rowsEditValues = apiRef.current.getRowWithUpdatedValues(id, '');
        const data = Object.entries(rowsEditValues).reduce(
          (person, [key, editProp]) => {
            // Strip username and empty values before sending to API
            if (key !== 'username' && editProp !== undefined && editProp !== '') {
              person[key] = editProp;
            } else {
              delete person[key];
            }
            return person;
          },
          { ...previousValues }
        );
        try {
          const person = PersonSchema.parse(data);
          await updatePerson(person).unwrap();
          const row = apiRef.current.getRow(id);
          apiRef.current.updateRows([{ id, ...row }]);
          apiRef.current.stopRowEditMode({ id });
        } catch (error) {
          const validationError = error as ZodError;
          apiRef.current.stopRowEditMode({
            id,
            ignoreModifications: true,
          });
          setUpdateError(validationError);
        }
      },
    [apiRef, updatePerson]
  );

  const handleDeleteClick = useCallback(
    ({ id }: GridRowParams) =>
      async (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        const person = apiRef.current.getRow(id) as Person;
        setPersonToDelete(person);
      },
    [apiRef]
  );

  const columns = useMemo(() => {
    const columns: (GridColDef | GridActionsColDef)[] = [
      { field: 'lastname', headerName: 'Cognome', width: 200, minWidth: 150, flex: 1, editable: true },
      { field: 'firstname', headerName: 'Nome', width: 200, minWidth: 130, flex: 1, editable: true },
      { field: 'company', headerName: 'Società', width: 250, minWidth: 150, flex: 1, editable: true },
      {
        ...plantColumn,
        field: 'plantID',
        headerName: 'Sito',
        width: 150,
        minWidth: 110,
        editable: true,
      },
      {
        field: 'phoneNumber',
        headerName: 'Telefono',
        minWidth: 150,
        sortable: false,
        filterable: false,
        editable: true,
      },
      {
        field: 'phoneNumberShort',
        headerName: 'T. abbr.',
        description: 'Telefono abbreviato',
        minWidth: 110,
        sortable: false,
        filterable: false,
        editable: true,
      },
      {
        field: 'mobileNumber',
        headerName: 'Cellulare',
        minWidth: 150,
        sortable: false,
        filterable: false,
        editable: true,
      },
      {
        field: 'mobileNumberShort',
        headerName: 'C. abbr.',
        description: 'Cellulare abbreviato',
        minWidth: 110,
        sortable: false,
        filterable: false,
        editable: true,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 300,
        minWidth: 100,
        renderCell: function renderEmailCell({ value }: GridCellParams<GridRowModel, string>) {
          return <a href={`mailto:${value}`}>{value}</a>;
        },
        sortable: false,
        filterable: false,
        editable: true,
      },
      { field: 'username', headerName: 'Username', width: 200, minWidth: 150 },
    ];
    if (isAdmin) {
      columns.push({
        type: 'actions',
        field: 'actions',
        headerName: 'Azioni',
        width: 90,
        align: 'left',
        renderHeader: () => <PersonAddButton />,
        getActions: (params: GridRowParams): React.ReactElement<GridActionsCellItemProps>[] => {
          const editMode = apiRef.current.getRowMode(params.id) === GridRowModes.Edit;
          if (editMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                key="save"
                label="Salva"
                onClick={handleSaveClick(params)}
                disabled={updatePersonState.isLoading}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                key="cancel"
                label="Annulla"
                onClick={handleCancelClick(params)}
                disabled={updatePersonState.isLoading}
              />,
            ];
          }
          const actions = [
            <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={handleEditClick(params)} />,
          ];
          if (!params.row.username) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                key="delete"
                label="Elimina"
                onClick={handleDeleteClick(params)}
              />
            );
          }
          return actions;
        },
      });
    }
    return columns;
  }, [
    apiRef,
    isAdmin,
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    plantColumn,
    updatePersonState.isLoading,
  ]);

  const rows = useMemo<Person[]>(() => {
    if (personList.data?.data) {
      return personList.data?.data;
    }
    return [];
  }, [personList.data]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel]
  );

  return (
    <AuthGuard authorizeRole={(r) => r === ADMIN} unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} error={personList.error} disableGutters sx={{ p: 1 }}>
        {updateError && (
          <Dialog open={true} onClose={() => setUpdateError(null)}>
            <ErrorAlert error={updateError} />
          </Dialog>
        )}
        <DataGridWrapper
          sx={{
            '& .Mui-error': { bgcolor: 'error.main', color: 'error.contrastText' },
          }}
        >
          <DataGrid
            apiRef={apiRef}
            density="compact"
            rows={rows}
            columns={columns}
            loading={personList.isFetching}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={personList.data?.total ?? 0}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            pinnedColumns={pinnedColumns}
          />
        </DataGridWrapper>
        {personToDelete && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare il contatto "${getPersonDisplayName(personToDelete)}"?`}
            confirmText="conferma"
            open={Boolean(personToDelete)}
            onConfirm={() => handleConfirmRemove(true)}
            onClose={() => handleConfirmRemove(false)}
            inProgress={removePersonState.isLoading}
            error={removePersonState.error}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
